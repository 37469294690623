.modal {
  background: rgba(0, 0, 0, 0.8);
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: auto;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;

  @media (max-width: 767px) {
    width: 90%;
  }

  .modal-content {
    border-radius: 4px;
    border: 0;
    background-color: #ffffff;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.36),
      0 2px 4px 0 rgba(0, 0, 0, 0.12);

    span:not(.required):not(.selbtngroup) {
      display: block;
    }

    .modal-info {
      padding: 30px;

      .title {
        font-size: 1.111rem;
        font-weight: bold;
        color: $secondary-color;
      }

      .message {
        font-size: 0.8889rem;
        color: $secondary-color-light;
      }
    }

    .modal-footer {
      padding: 8px 18px;

      button {
        border: 0;
        text-transform: uppercase;
        font-size: 0.7778rem;
        font-weight: bold;
        color: rgba(49, 32, 32, 0.58);

        &:hover {
          background: none;
        }

        &:last-child {
          color: $primary-color;
        }
      }
    }
  }
}
