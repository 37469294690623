/*
**************************************************************
INDEX
**************************************************************

1. Import
2. Reset
3. Fonts
	3.1. Icons
4. Layouts
	4.1. Container page
	4.2. Main Container
	4.3. Loading
5. Forms
6. Animations
	6.1. Login
	6.2. ZoomIn
	6.3. FadeIn
7. Scroll

*/
/*
**************************************************************
1. Import
**************************************************************
*/
@import '~@ng-select/ng-select/themes/default.theme.css';
/*
**************************************************************
2. Reset
**************************************************************
*/
*:active,
*:focus,
*:link,
*:visited {
  outline: none !important;
}

button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

body {
  background: #2d383b;
}

*,
body,
html {
  font-family: $regular-font;
  font-size: 18px;
  color: #4a4d4e;
  text-rendering: optimizeLegibility;
}

/* Right drawer */
.right-drawer {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  width: 530px;
  height: 100%;
  padding: 105px 60px 25px 60px;
  background: #fff;
  -webkit-box-shadow: -8px 0 40px 0 rgba(0, 0, 0, 0.36);
  box-shadow: -8px 0 40px 0 rgba(0, 0, 0, 0.36);
  overflow-y: auto;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  @media (max-width: 768px) {
    z-index: 99999;
    width: 100%;
    padding: 105px 30px 25px 30px;
  }

  .required {
    position: absolute;
    top: 45px;
    overflow: hidden;
    font-size: 0.6667rem;
    color: $primary-color;
  }

  form {
    margin-top: 15px;

    .obligatory-field {
      font-size: 0.7778rem;
    }

    label {
      font-weight: bold;
      font-size: 0.6667rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $secondary-color-light;
      overflow: hidden;
    }

    p {
      font-weight: bold;
      font-size: 0.59rem;
      letter-spacing: 1px;
      color: $secondary-color-light;
      overflow: hidden;
    }

    .form-control:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  button.vdp-icon-cancelar {
    font-size: 2rem;
    background: none;
    border: 0;
    padding: 0;
    margin-left: -46px;
    color: $secondary-color-light;
    @include transition;

    @media (max-width: 768px) {
      margin-left: -14px;
    }

    &:hover {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    + span {
      position: relative;
      top: -10px;
      left: 5px;
      font-weight: bold;
      font-size: 0.8889rem;
      color: $primary-color;
    }
  }
}

/* Tags */
.tag {
  position: absolute;
  border-radius: 2px;
  top: 6px;
  right: -22px;
  background-color: rgba(230, 0, 0, 0.1);
  padding: 5px 5px;
  color: $primary-color;
  font-size: 0.6667rem;

  &.tag-warning {
    background-color: #c87d0f;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    position: absolute;
    display: inline-block;
    bottom: inherit;
  }
}

/* Modal*/
.modal.editmodal {
  z-index: 999999;
  opacity: 1;
  display: block;
  -webkit-animation: fadeIn 0.4s;
  animation: fadeIn 0.4s;

  .modal-dialog {
    min-width: 500px;
  }

  .modal-info {
    .vdp-icon-cancelar {
      border: 0;
      background: transparent;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0;
      color: $secondary-color-light;
    }

    .form-group {
      padding: 0;
      margin: 25px 0 0;

      &:last-child {
        margin: 0;
      }
    }

    .ng-autocomplete {
      max-width: 100%;
    }

    .title {
      color: $primary-color;
      font-size: 0.8889rem;
      letter-spacing: initial;
      text-transform: none;
    }

    .roomName {
      font-family: $regular-font;
      font-size: 1.111rem;
      color: #171717;
    }

    label {
      font-weight: bold;
      font-size: 0.6667rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #8f8f8f;
      overflow: hidden;
    }

    .mui-textfield {
      padding-top: 15px !important;
      margin-bottom: 20px !important;

      textarea:focus {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
    .reservation-button {
      @include primary-button;
      font-size: 0.6667rem;
      display: block;
      margin: 0 auto;
      letter-spacing: 1px;
      cursor: pointer;
    }

    .mui-select__menu {
      height: 40vh;
    }

    .required {
      font-size: 0.6667rem;
      color: $primary-color;
    }
  }
}

/*
**************************************************************
4. Layouts
**************************************************************
*/
/* 4.1. Container page */
.container-page {
  width: 100%;
  min-height: 100%;
  background: #2d383b;
  -webkit-animation: zoomIn 1s;
  animation: zoomIn 1s;

  /* 4.1.1. Login */
  .login {
    display: table;
    width: 100%;
    min-height: 100%;
    height: 100%;
    background: $background-color;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* 4.2. Main Container */
.main-container {
  background: #f6f7f8;
  width: calc(100% - 300px);
  height: calc(100vh - 80px);
  padding: 40px;
  overflow-y: auto;
  @include transition;
}

.listRooms {
  padding: 40px;
  @include transition;
}

.collapsed {
  .main-container {
    width: calc(100% - 85px) !important;
    padding: 40px 110px;

    @media (max-width: 1192px) {
      padding: 40px;
    }

    @media (max-width: 768px) {
      width: 100% !important;
      padding: 105px 20px 20px 20px;
      height: 100%;
    }
  }

  .main-container-tab {
    width: calc(100% - 85px) !important;

    @media (max-width: 768px) {
      width: 100% !important;
      height: 100% !important;
      padding-top: 80px !important;
    }

    .tabBuildings {
      @media (min-width: 1193px) {
        padding: 0 110px;
      }
    }

    .listRooms {
      padding: 40px 110px;
      @media (max-width: 1192px) {
        padding: 40px;
      }
    }
  }

  .add-meeting-room-detail,
  .reservation-detail-layer,
  .roomDetail {
    width: calc(100% - 85px) !important;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
}

/* 4.3. Loading */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d383b;
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
  display: block;

  .spinner-wrapper {
    position: absolute;
    width: 100px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .spinner {
      width: 80px;
      height: 80px;
      position: relative;
      text-align: center;
      -webkit-animation: sk-rotate 2s infinite linear;
      animation: sk-rotate 2s infinite linear;
    }

    .dot1,
    .dot2 {
      width: 60%;
      height: 60%;
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: #ffffff;
      border-radius: 100%;
      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .dot2 {
      top: auto;
      bottom: 0;
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
    @-webkit-keyframes sk-rotate {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes sk-rotate {
      100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes sk-bounce {
      0%,
      100% {
        -webkit-transform: scale(0);
      }

      50% {
        -webkit-transform: scale(1);
      }
    }
    @keyframes sk-bounce {
      0%,
      100% {
        transform: scale(0);
        -webkit-transform: scale(0);
      }

      50% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }
  }
}

/*
**************************************************************
4. Forms
**************************************************************
*/

.form-group:first-child {
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
}

.mui-textfield > textarea {
  height: 0;
  line-height: 26px;
  margin-top: 5px;
  min-height: 40px;
}

.select-group {
  margin-top: 15px;

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  .mui-select {
    float: left;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }

    label {
      overflow: visible !important;
    }

    + span {
      float: left;
      position: relative;
      top: 20px;
      margin: 0 3px;
      left: 0;
    }

    select {
      padding: 0;
    }
  }
}

.mui-select > select,
.mui-textfield:not(.border-on) > input,
.mui-textfield > textarea {
  color: $secondary-color;
  font-size: 1.444rem;
  border: 0;
  background-image: none;
}

.mui-textfield > textarea[rows]:not([rows='2']):focus {
  margin-bottom: 0 !important;
}

.custom-datepicker {
  &#fecha {
    .mydp {
      width: initial !important;
      height: 0 !important;
    }
  }

  .mydp {
    border: 0;
    display: block;

    .btnclearenabled {
      border: 0;

      &:focus,
      &:hover {
        background: transparent;
      }
    }

    .selectiongroup {
      width: 100%;
    }

    input {
      cursor: pointer;
      height: 33px !important;
      padding: 0 !important;
      width: 100%;
      border: 0;
      text-align: left;
      font-weight: normal;
      font-family: $regular-font;
      font-size: 1.444rem;
      color: $secondary-color;

      &::-webkit-input-placeholder {
        font-family: $regular-font;
        font-size: 1.444rem !important;
        font-weight: normal !important;
        color: #bfbfbf;
        text-align: left !important;
      }

      &::-moz-placeholder {
        font-family: $regular-font;
        font-size: 1.444rem !important;
        font-weight: normal !important;
        color: #bfbfbf;
        text-align: left !important;
      }

      &:-ms-input-placeholder {
        font-family: $regular-font;
        font-size: 1.444rem !important;
        font-weight: normal !important;
        color: #bfbfbf;
        text-align: left !important;
      }

      &:-moz-placeholder {
        font-family: $regular-font;
        font-size: 1.444rem !important;
        font-weight: normal !important;
        color: #bfbfbf;
        text-align: left !important;
      }
    }

    .selector {
      border: 0;
      padding: 20px;
      background: #fff;
      -webkit-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.06),
        0 0 6px 0 rgba(0, 0, 0, 0.08);
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.06), 0 0 6px 0 rgba(0, 0, 0, 0.08);
      width: 310px;

      * {
        background: transparent !important;
        font-size: 0.8889rem;
        color: $secondary-color-light;
        font-family: $regular-font;
      }

      &:before {
        display: none;
      }

      td {
        padding: 10px;

        &.disabled {
          background: rgba(0, 0, 0, 0.03) !important;

          span:hover {
            color: #8f8f8f !important;
          }
        }
      }

      .selectedday {
        background: $primary-color !important; /* Old browsers */
        border-radius: 50%;
        -webkit-box-shadow: 0 2px 10px 0 rgba(253, 44, 43, 0.3);
        box-shadow: 0 2px 10px 0 rgba(253, 44, 43, 0.3);

        div {
          border: 0;

          span {
            color: #fff;
          }
        }
      }

      .currday {
        color: $primary-color;
        text-decoration: none;
      }

      span:hover {
        color: $primary-color;
      }

      .headerlabelbtn {
        font-weight: bold;

        &:focus,
        &:hover {
          color: inherit;
        }
      }

      .headermonthtxt {
        display: block;
        margin: 0 auto;
      }

      .vdp-plegar-menu {
        font-size: 1.5rem;

        &:focus,
        &:hover {
          color: inherit;
        }

        &.left {
          position: relative;
          top: -8px;
        }

        &.right {
          position: relative;
          top: 1px;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
}
/*
**************************************************************
4. Animations
**************************************************************
*/
/* 4.1 Login */
@-webkit-keyframes login-appear {
  0% {
    opacity: 0;
    top: -100px;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(1500px) rotateX(25deg);
    transform: perspective(1500px) rotateX(25deg);
    -moz-transform: perspective(1500px) rotateX(25deg);
    -ms-transform: perspective(1500px) rotateX(25deg);
    -o-transform: perspective(1500px) rotateX(25deg);
  }

  100% {
    opacity: 1;
    top: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(0) rotateX(0);
    transform: perspective(0) rotateX(0);
    -moz-transform: perspective(0) rotateX(0);
    -ms-transform: perspective(0) rotateX(0);
    -o-transform: perspective(0) rotateX(0);
  }
}
@keyframes login-appear {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    top: -100px;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(1500px) rotateX(25deg);
    transform: perspective(1500px) rotateX(25deg);
    -moz-transform: perspective(1500px) rotateX(25deg);
    -ms-transform: perspective(1500px) rotateX(25deg);
    -o-transform: perspective(1500px) rotateX(25deg);
  }

  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    top: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(0) rotateX(0);
    transform: perspective(0) rotateX(0);
    -moz-transform: perspective(0) rotateX(0);
    -ms-transform: perspective(0) rotateX(0);
    -o-transform: perspective(0) rotateX(0);
  }
}

.login-form, .loginType-box {
  -webkit-animation: login-appear 0.5s;
  animation: login-appear 0.5s;
}
/* 6.2. ZoomIn */
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* 6.3. FadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInLayer {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.fadeInLayer-delay {
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
}

/*
**************************************************************
7. Scroll
**************************************************************
*/
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: $secondary-color-light;
}

/* Filter*/
.date-filter {
  display: inline-block;
  vertical-align: bottom;

  .filterBy {
    float: left;
    font-weight: bold;
    color: #8f8f8f;
    line-height: 34px;
    font-size: 0.6667rem;
    text-transform: uppercase;
    margin-right: 35px;
  }

  datepicker {
    .mydp {
      float: left;

      .selectiongroup {
        display: inline-block;
        background: #fff;
        border: 2px solid #e4e5e6;
        width: 34px !important;
        height: 34px;
        border-radius: 6px;
        -webkit-box-shadow: 0 3px 10px 0 #e4e5e6;
        box-shadow: 0 3px 10px 0 #e4e5e6;

        &:before {
          content: '\e93f';
          font-size: 1rem;
          font-family: 'vdp-icons' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          top: 6px;
          left: 6px;
        }

        input {
          opacity: 0;
        }

        .selbtngroup {
          left: -33px;
          top: -2px;

          button {
            background: transparent;
          }
        }
      }

      .selector {
        right: 0;

        &:after {
          right: 7px;
          left: initial;
        }
      }
    }
  }
}

/*
**************************************************************
8. Checkbox
**************************************************************
*/

.checkbox {
  display: block;

  input[type='checkbox'] {
    display: none;
  }

  label {
    position: relative;
    display: inline-block;
    padding-top: 3px;
    padding-left: 30px;
    cursor: pointer;
    font-size: 0.6667rem;
    font-weight: 300;
    color: #8f8f8f;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  label::before,
  label::after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  label::before {
    height: 20px;
    width: 20px;
    border: 1px solid $secondary-color-light;
    left: 0px;
    border-radius: 2px;
    top: 5px;
  }

  input[type='checkbox']:checked + label::before {
    background-color: $primary-color;
    border-color: $secondary-color;
  }

  label::after {
    height: 5px;
    width: 13px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-45deg);
    left: 4px;
    top: 10px;
  }

  input[type='checkbox'] + label::after {
    content: none;
  }

  input[type='checkbox']:checked + label::after {
    content: '';
  }
}

/*
**************************************************************
9. Cards 
**************************************************************
*/

.available-position-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 25px;
  margin-bottom: 25px;
  cursor: pointer;

  &.selected {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    .position {
      span {
        color: white;
      }
    }

    .floor {
      color: white;
    }

    .date {
      color: white;
    }

    .icon {
      i {
        color: white;
      }
    }
  }

  .position {
    text-transform: uppercase;
    font-weight: bold;
    color: $primary-color;
    padding-bottom: 5px;

    span {
      color: $primary-color;
    }
  }

  .floor {
    font-weight: bold;
    font-size: 14px;
    color: $secondary-color;
  }

  .date {
    font-size: 14px;
    color: $secondary-color-light;
  }

  .icon {
    i {
      font-size: 24px;
      color: $primary-color;
      cursor: pointer;
    }
  }
}
/*
**************************************************************
9. NG-SELECT 
**************************************************************
*/

.ng-select .ng-select-container {
  font-family: $regular-font;
  font-weight: normal;
  font-style: normal;
  border: 0;
  padding: 0;
  justify-content: center;
  align-items: end;

  &:hover {
    box-shadow: none !important;
  }

  input {
    height: auto;
    padding: 0;
  }

  & .ng-value-container {
    padding: 0 !important;
    margin-right: 10px;

    & .ng-placeholder {
      font-size: 1.444rem;
      padding: 0 !important;
    }
  }
  & .ng-arrow-wrapper  {
    margin-bottom: 5px;
  }
}

.ng-select.ng-select-multiple {
  & .ng-select-container .ng-value-container .ng-value {
    background-color: #e0e0e0;
    white-space: break-spaces !important;

    & .ng-value-icon.left {
      border-right: none !important;
      display: none;
    }
    & .ng-value-icon:hover {
      background-color: #e0e0e0;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  //max-height: none !important;

  & .ng-option {
    padding: 0 22px;
    height: 42px;
    line-height: 42px;
    cursor: pointer;
    white-space: nowrap;

    &.ng-option-marked {
      background-color: #e0e0e0;
    }
    &.ng-option-selected {
      background-color: #eee;

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}
