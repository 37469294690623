@charset "UTF-8";
@import '~@ng-select/ng-select/themes/default.theme.css';
:root {
  --primary-color: #063c67;
  --primary-color-light: #09518b;
  --primary-color-dark: #062d4c;
  --primary-color-darker: #063c67;
  --primary-color-lighter: #042037;
  --secondary-color: #4a4d4e;
  --secondary-color-light: #4a4d4e;
  --background-color: #2d383b;
  --navbar-background: #fff;
  --navbar-color-inverse: #333;
  --navbar-color-inverse-light: #707070;
  --navbar-color-inverse-lighter: #8f8f8f;
  --navbar-link-hover: #fff;
  --navbar-link-hover-background: var(--primary-color-light);
  --logo-background: var(--navbar-background);
  --logo-color-inverse: var(--navbar-color-inverse);
  --icon-color-parkingElectric: #fff;
  --icon-color-parkingWaiting: #fff;
}

/*
**************************************************************
Colors
**************************************************************
*/
@font-face {
  font-family: "SmartBuilding";
  src: url("/assets/fonts/aller_rg-webfont.eot");
  src: url("/assets/fonts/aller_rg-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/aller_rg-webfont.woff2") format("woff2"), url("/assets/fonts/aller_rg-webfont.woff") format("woff"), url("/assets/fonts/aller_rg-webfont.ttf") format("truetype"), url("/assets/fonts/aller_rg-webfont.svg#aller_rg-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SmartBuilding Bold";
  src: url("/assets/fonts/aller_bd-webfont.eot");
  src: url("/assets/fonts/aller_bd-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/aller_bd-webfont.woff2") format("woff2"), url("/assets/fonts/aller_bd-webfont.woff") format("woff"), url("/assets/fonts/aller_bd-webfont.ttf") format("truetype"), url("/assets/fonts/aller_bd-webfont.svg#aller_bd-webfont") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "SmartBuilding Light";
  src: url("/assets/fonts/aller_lt-webfont.eot");
  src: url("/assets/fonts/aller_lt-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/aller_lt-webfont.woff2") format("woff2"), url("/assets/fonts/aller_lt-webfont.woff") format("woff"), url("/assets/fonts/aller_lt-webfont.ttf") format("truetype"), url("/assets/fonts/aller_lt-webfont.svg#aller_lt-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Primary gradient */
/* Primary button */
/* Secondary button */
/* Transition */
/*
**************************************************************
Main Logo && image
**************************************************************
*/
@font-face {
  font-family: "vdp-icons";
  src: url("/assets/fonts/vdp-icons.eot?kz72pl");
  src: url("/assets/fonts/vdp-icons.eot?kz72pl#iefix") format("embedded-opentype"), url("/assets/fonts/vdp-icons.ttf?kz72pl") format("truetype"), url("/assets/fonts/vdp-icons.woff?kz72pl") format("woff"), url("/assets/fonts/vdp-icons.svg?kz72pl#vdp-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=vdp-],
[class*=" vdp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "vdp-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vdp-supportIcon:before {
  content: "";
}

.vdp-waves:before {
  content: "";
}

.vdp-config_lanzaderas:before {
  content: "";
}

.vdp-calendarParkingmotoQueue:before {
  content: "";
}

.vdp-calendarParkingmotoCharge:before {
  content: "";
}

.vdp-calendarParkingQueue:before {
  content: "";
}

.vdp-calendarParkingCharge:before {
  content: "";
}

.vdp-calendar:before {
  content: "";
}

.vdp-puesto_comedor:before {
  content: "";
}

.vdp-config_restaurant:before {
  content: "";
}

.vdp-parkingMotoBig:before {
  content: "";
}

.vdp-parking_big:before {
  content: "";
}

.vdp-comedor_big:before {
  content: "";
}

.vdp-coche_normal:before {
  content: "";
}

.vdp-coche_electrico:before {
  content: "";
}

.vdp-user-x:before {
  content: "";
}

.vdp-save:before {
  content: "";
}

.vdp-puestos-restaurante:before {
  content: "";
}

.vdp-go-back:before {
  content: "";
}

.vdp-map:before {
  content: "";
}

.vdp-reserva-multiple:before {
  content: "";
}

.vdp-buscar-usuario:before {
  content: "";
}

.vdp-reserve-gym-resources:before {
  content: "";
}

.vdp-share:before {
  content: "";
}

.vdp-invitar:before {
  content: "";
}

.vdp-puesto_trabajo:before {
  content: "";
}

.vdp-close-lateral-foto:before {
  content: "";
}

.vdp-equipation-accesorios:before {
  content: "";
}

.vdp-equipation-conferencia:before {
  content: "";
}

.vdp-equipation-impresora:before {
  content: "";
}

.vdp-equipation-mesa:before {
  content: "";
}

.vdp-equipation-portatil:before {
  content: "";
}

.vdp-equipation-tablet:before {
  content: "";
}

.vdp-equipation-telefono:before {
  content: "";
}

.vdp-equipation-television:before {
  content: "";
}

.vdp-equipation-wifi:before {
  content: "";
}

.vdp-icon_password:before {
  content: "";
}

.vdp-icon-eye-closed:before {
  content: "";
}

.vdp-icon-eye-open:before {
  content: "";
}

.vdp-icon_usuario:before {
  content: "";
}

.vdp-icon-cancelar:before {
  content: "";
}

.vdp-icon-mas:before {
  content: "";
}

.vdp-icon-reserva-express:before {
  content: "";
}

.vdp-icon-reserva-sala:before {
  content: "";
}

.vdp-label-asistentes:before {
  content: "";
}

.vdp-logout:before {
  content: "";
}

.vdp-navbar-perfil-activo:before {
  content: "";
}

.vdp-navbar-salas:before {
  content: "";
}

.vdp-navbar-salas-activo:before {
  content: "";
}

.vdp-plegar-menu:before {
  content: "";
}

.vdp-salas-asistentes:before {
  content: "";
}

.vdp-salas-incidencia:before {
  content: "";
}

.vdp-salas-alerta:before {
  content: "";
}

.vdp-vista-imagen:before {
  content: "";
}

.vdp-vista-listado:before {
  content: "";
}

.vdp-salas-lunch:before {
  content: "";
}

.vdp-filtro:before {
  content: "";
}

.vdp-android:before {
  content: "";
}

.vdp-ios:before {
  content: "";
}

.vdp-navbar-descargar-activo:before {
  content: "";
}

.vdp-gym-resources:before {
  content: "";
}

.vdp-icon-buscar-sala:before {
  content: "";
}

.vdp-icon-reserva-speed:before {
  content: "";
}

.vdp-icon-reserva-sede:before {
  content: "";
}

.vdp-navbar-parking-activo:before {
  content: "";
}

.vdp-warning:before {
  content: "";
}

/*
**************************************************************
INDEX
**************************************************************

1. Import
2. Reset
3. Fonts
	3.1. Icons
4. Layouts
	4.1. Container page
	4.2. Main Container
	4.3. Loading
5. Forms
6. Animations
	6.1. Login
	6.2. ZoomIn
	6.3. FadeIn
7. Scroll

*/
/*
**************************************************************
1. Import
**************************************************************
*/
/*
**************************************************************
2. Reset
**************************************************************
*/
*:active,
*:focus,
*:link,
*:visited {
  outline: none !important;
}

button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

body {
  background: #2d383b;
}

*,
body,
html {
  font-family: "SmartBuilding", sans-serif;
  font-size: 18px;
  color: #4a4d4e;
  text-rendering: optimizeLegibility;
}

/* Right drawer */
.right-drawer {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  width: 530px;
  height: 100%;
  padding: 105px 60px 25px 60px;
  background: #fff;
  -webkit-box-shadow: -8px 0 40px 0 rgba(0, 0, 0, 0.36);
  box-shadow: -8px 0 40px 0 rgba(0, 0, 0, 0.36);
  overflow-y: auto;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 768px) {
  .right-drawer {
    z-index: 99999;
    width: 100%;
    padding: 105px 30px 25px 30px;
  }
}
.right-drawer .required {
  position: absolute;
  top: 45px;
  overflow: hidden;
  font-size: 0.6667rem;
  color: var(--primary-color);
}
.right-drawer form {
  margin-top: 15px;
}
.right-drawer form .obligatory-field {
  font-size: 0.7778rem;
}
.right-drawer form label {
  font-weight: bold;
  font-size: 0.6667rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--secondary-color-light);
  overflow: hidden;
}
.right-drawer form p {
  font-weight: bold;
  font-size: 0.59rem;
  letter-spacing: 1px;
  color: var(--secondary-color-light);
  overflow: hidden;
}
.right-drawer form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.right-drawer button.vdp-icon-cancelar {
  font-size: 2rem;
  background: none;
  border: 0;
  padding: 0;
  margin-left: -46px;
  color: var(--secondary-color-light);
  transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
}
@media (max-width: 768px) {
  .right-drawer button.vdp-icon-cancelar {
    margin-left: -14px;
  }
}
.right-drawer button.vdp-icon-cancelar:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.right-drawer button.vdp-icon-cancelar + span {
  position: relative;
  top: -10px;
  left: 5px;
  font-weight: bold;
  font-size: 0.8889rem;
  color: var(--primary-color);
}

/* Tags */
.tag {
  position: absolute;
  border-radius: 2px;
  top: 6px;
  right: -22px;
  background-color: rgba(230, 0, 0, 0.1);
  padding: 5px 5px;
  color: var(--primary-color);
  font-size: 0.6667rem;
}
.tag.tag-warning {
  background-color: #c87d0f;
  color: #ffffff;
}
@media (max-width: 768px) {
  .tag {
    position: absolute;
    display: inline-block;
    bottom: inherit;
  }
}

/* Modal*/
.modal.editmodal {
  z-index: 999999;
  opacity: 1;
  display: block;
  -webkit-animation: fadeIn 0.4s;
  animation: fadeIn 0.4s;
}
.modal.editmodal .modal-dialog {
  min-width: 500px;
}
.modal.editmodal .modal-info .vdp-icon-cancelar {
  border: 0;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  color: var(--secondary-color-light);
}
.modal.editmodal .modal-info .form-group {
  padding: 0;
  margin: 25px 0 0;
}
.modal.editmodal .modal-info .form-group:last-child {
  margin: 0;
}
.modal.editmodal .modal-info .ng-autocomplete {
  max-width: 100%;
}
.modal.editmodal .modal-info .title {
  color: var(--primary-color);
  font-size: 0.8889rem;
  letter-spacing: initial;
  text-transform: none;
}
.modal.editmodal .modal-info .roomName {
  font-family: "SmartBuilding", sans-serif;
  font-size: 1.111rem;
  color: #171717;
}
.modal.editmodal .modal-info label {
  font-weight: bold;
  font-size: 0.6667rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8f8f8f;
  overflow: hidden;
}
.modal.editmodal .modal-info .mui-textfield {
  padding-top: 15px !important;
  margin-bottom: 20px !important;
}
.modal.editmodal .modal-info .mui-textfield textarea:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.modal.editmodal .modal-info .reservation-button {
  background: var(--primary-color-light);
  background: -webkit-gradient(linear, left top, left bottom, from(var(--primary-color-light)), to(var(--primary-color)));
  background: linear-gradient(to bottom, var(--primary-color-light) 0, var(--primary-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-color-light, endColorstr=$primary-color, GradientType=0 );
  border-radius: 100px;
  -webkit-box-shadow: 0 7px 11px 0 #000000c0;
  box-shadow: 0 7px 11px 0 #000000c0;
  padding: 12px 20px;
  color: #fff;
  font-weight: bold;
  border: 0;
  transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
  font-size: 0.6667rem;
  display: block;
  margin: 0 auto;
  letter-spacing: 1px;
  cursor: pointer;
}
.modal.editmodal .modal-info .reservation-button:hover {
  background: var(--primary-color-dark);
  background: -webkit-gradient(linear, left top, left bottom, from(var(--primary-color-darker)), to(var(--primary-color-dark)));
  background: linear-gradient(to bottom, var(--primary-color-dark) 0, var(--primary-color-darker) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-color-darker, endColorstr=$primary-color-darker, GradientType=0 );
}
.modal.editmodal .modal-info .mui-select__menu {
  height: 40vh;
}
.modal.editmodal .modal-info .required {
  font-size: 0.6667rem;
  color: var(--primary-color);
}

/*
**************************************************************
4. Layouts
**************************************************************
*/
/* 4.1. Container page */
.container-page {
  width: 100%;
  min-height: 100%;
  background: #2d383b;
  -webkit-animation: zoomIn 1s;
  animation: zoomIn 1s;
  /* 4.1.1. Login */
}
.container-page .login {
  display: table;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: var(--background-color);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* 4.2. Main Container */
.main-container {
  background: #f6f7f8;
  width: calc(100% - 300px);
  height: calc(100vh - 80px);
  padding: 40px;
  overflow-y: auto;
  transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
}

.listRooms {
  padding: 40px;
  transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
}

.collapsed .main-container {
  width: calc(100% - 85px) !important;
  padding: 40px 110px;
}
@media (max-width: 1192px) {
  .collapsed .main-container {
    padding: 40px;
  }
}
@media (max-width: 768px) {
  .collapsed .main-container {
    width: 100% !important;
    padding: 105px 20px 20px 20px;
    height: 100%;
  }
}
.collapsed .main-container-tab {
  width: calc(100% - 85px) !important;
}
@media (max-width: 768px) {
  .collapsed .main-container-tab {
    width: 100% !important;
    height: 100% !important;
    padding-top: 80px !important;
  }
}
@media (min-width: 1193px) {
  .collapsed .main-container-tab .tabBuildings {
    padding: 0 110px;
  }
}
.collapsed .main-container-tab .listRooms {
  padding: 40px 110px;
}
@media (max-width: 1192px) {
  .collapsed .main-container-tab .listRooms {
    padding: 40px;
  }
}
.collapsed .add-meeting-room-detail,
.collapsed .reservation-detail-layer,
.collapsed .roomDetail {
  width: calc(100% - 85px) !important;
}
@media (max-width: 768px) {
  .collapsed .add-meeting-room-detail,
.collapsed .reservation-detail-layer,
.collapsed .roomDetail {
    width: 100% !important;
  }
}

/* 4.3. Loading */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d383b;
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
  display: block;
}
.loading .spinner-wrapper {
  position: absolute;
  width: 100px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading .spinner-wrapper .spinner {
  width: 80px;
  height: 80px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}
.loading .spinner-wrapper .dot1,
.loading .spinner-wrapper .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.loading .spinner-wrapper .dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*
**************************************************************
4. Forms
**************************************************************
*/
@media (max-width: 768px) {
  .form-group:first-child {
    margin-bottom: 50px;
  }
}

.mui-textfield > textarea {
  height: 0;
  line-height: 26px;
  margin-top: 5px;
  min-height: 40px;
}

.select-group {
  margin-top: 15px;
}
.select-group:after {
  content: "";
  clear: both;
  display: table;
}
.select-group .mui-select {
  float: left;
}
@media (max-width: 768px) {
  .select-group .mui-select {
    margin-bottom: 0;
  }
}
.select-group .mui-select label {
  overflow: visible !important;
}
.select-group .mui-select + span {
  float: left;
  position: relative;
  top: 20px;
  margin: 0 3px;
  left: 0;
}
.select-group .mui-select select {
  padding: 0;
}

.mui-select > select,
.mui-textfield:not(.border-on) > input,
.mui-textfield > textarea {
  color: var(--secondary-color);
  font-size: 1.444rem;
  border: 0;
  background-image: none;
}

.mui-textfield > textarea[rows]:not([rows="2"]):focus {
  margin-bottom: 0 !important;
}

.custom-datepicker#fecha .mydp {
  width: initial !important;
  height: 0 !important;
}
.custom-datepicker .mydp {
  border: 0;
  display: block;
}
.custom-datepicker .mydp .btnclearenabled {
  border: 0;
}
.custom-datepicker .mydp .btnclearenabled:focus, .custom-datepicker .mydp .btnclearenabled:hover {
  background: transparent;
}
.custom-datepicker .mydp .selectiongroup {
  width: 100%;
}
.custom-datepicker .mydp input {
  cursor: pointer;
  height: 33px !important;
  padding: 0 !important;
  width: 100%;
  border: 0;
  text-align: left;
  font-weight: normal;
  font-family: "SmartBuilding", sans-serif;
  font-size: 1.444rem;
  color: var(--secondary-color);
}
.custom-datepicker .mydp input::-webkit-input-placeholder {
  font-family: "SmartBuilding", sans-serif;
  font-size: 1.444rem !important;
  font-weight: normal !important;
  color: #bfbfbf;
  text-align: left !important;
}
.custom-datepicker .mydp input::-moz-placeholder {
  font-family: "SmartBuilding", sans-serif;
  font-size: 1.444rem !important;
  font-weight: normal !important;
  color: #bfbfbf;
  text-align: left !important;
}
.custom-datepicker .mydp input:-ms-input-placeholder {
  font-family: "SmartBuilding", sans-serif;
  font-size: 1.444rem !important;
  font-weight: normal !important;
  color: #bfbfbf;
  text-align: left !important;
}
.custom-datepicker .mydp input:-moz-placeholder {
  font-family: "SmartBuilding", sans-serif;
  font-size: 1.444rem !important;
  font-weight: normal !important;
  color: #bfbfbf;
  text-align: left !important;
}
.custom-datepicker .mydp .selector {
  border: 0;
  padding: 20px;
  background: #fff;
  -webkit-box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.06), 0 0 6px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.06), 0 0 6px 0 rgba(0, 0, 0, 0.08);
  width: 310px;
}
.custom-datepicker .mydp .selector * {
  background: transparent !important;
  font-size: 0.8889rem;
  color: var(--secondary-color-light);
  font-family: "SmartBuilding", sans-serif;
}
.custom-datepicker .mydp .selector:before {
  display: none;
}
.custom-datepicker .mydp .selector td {
  padding: 10px;
}
.custom-datepicker .mydp .selector td.disabled {
  background: rgba(0, 0, 0, 0.03) !important;
}
.custom-datepicker .mydp .selector td.disabled span:hover {
  color: #8f8f8f !important;
}
.custom-datepicker .mydp .selector .selectedday {
  background: var(--primary-color) !important;
  /* Old browsers */
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 10px 0 rgba(253, 44, 43, 0.3);
  box-shadow: 0 2px 10px 0 rgba(253, 44, 43, 0.3);
}
.custom-datepicker .mydp .selector .selectedday div {
  border: 0;
}
.custom-datepicker .mydp .selector .selectedday div span {
  color: #fff;
}
.custom-datepicker .mydp .selector .currday {
  color: var(--primary-color);
  text-decoration: none;
}
.custom-datepicker .mydp .selector span:hover {
  color: var(--primary-color);
}
.custom-datepicker .mydp .selector .headerlabelbtn {
  font-weight: bold;
}
.custom-datepicker .mydp .selector .headerlabelbtn:focus, .custom-datepicker .mydp .selector .headerlabelbtn:hover {
  color: inherit;
}
.custom-datepicker .mydp .selector .headermonthtxt {
  display: block;
  margin: 0 auto;
}
.custom-datepicker .mydp .selector .vdp-plegar-menu {
  font-size: 1.5rem;
}
.custom-datepicker .mydp .selector .vdp-plegar-menu:focus, .custom-datepicker .mydp .selector .vdp-plegar-menu:hover {
  color: inherit;
}
.custom-datepicker .mydp .selector .vdp-plegar-menu.left {
  position: relative;
  top: -8px;
}
.custom-datepicker .mydp .selector .vdp-plegar-menu.right {
  position: relative;
  top: 1px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*
**************************************************************
4. Animations
**************************************************************
*/
/* 4.1 Login */
@-webkit-keyframes login-appear {
  0% {
    opacity: 0;
    top: -100px;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(1500px) rotateX(25deg);
    transform: perspective(1500px) rotateX(25deg);
    -moz-transform: perspective(1500px) rotateX(25deg);
    -ms-transform: perspective(1500px) rotateX(25deg);
    -o-transform: perspective(1500px) rotateX(25deg);
  }
  100% {
    opacity: 1;
    top: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(0) rotateX(0);
    transform: perspective(0) rotateX(0);
    -moz-transform: perspective(0) rotateX(0);
    -ms-transform: perspective(0) rotateX(0);
    -o-transform: perspective(0) rotateX(0);
  }
}
@keyframes login-appear {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    top: -100px;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(1500px) rotateX(25deg);
    transform: perspective(1500px) rotateX(25deg);
    -moz-transform: perspective(1500px) rotateX(25deg);
    -ms-transform: perspective(1500px) rotateX(25deg);
    -o-transform: perspective(1500px) rotateX(25deg);
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    top: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -webkit-transform: perspective(0) rotateX(0);
    transform: perspective(0) rotateX(0);
    -moz-transform: perspective(0) rotateX(0);
    -ms-transform: perspective(0) rotateX(0);
    -o-transform: perspective(0) rotateX(0);
  }
}
.login-form, .loginType-box {
  -webkit-animation: login-appear 0.5s;
  animation: login-appear 0.5s;
}

/* 6.2. ZoomIn */
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* 6.3. FadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeInLayer {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.fadeInLayer-delay {
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
}

/*
**************************************************************
7. Scroll
**************************************************************
*/
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color-light);
}

/* Filter*/
.date-filter {
  display: inline-block;
  vertical-align: bottom;
}
.date-filter .filterBy {
  float: left;
  font-weight: bold;
  color: #8f8f8f;
  line-height: 34px;
  font-size: 0.6667rem;
  text-transform: uppercase;
  margin-right: 35px;
}
.date-filter datepicker .mydp {
  float: left;
}
.date-filter datepicker .mydp .selectiongroup {
  display: inline-block;
  background: #fff;
  border: 2px solid #e4e5e6;
  width: 34px !important;
  height: 34px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 10px 0 #e4e5e6;
  box-shadow: 0 3px 10px 0 #e4e5e6;
}
.date-filter datepicker .mydp .selectiongroup:before {
  content: "";
  font-size: 1rem;
  font-family: "vdp-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 6px;
  left: 6px;
}
.date-filter datepicker .mydp .selectiongroup input {
  opacity: 0;
}
.date-filter datepicker .mydp .selectiongroup .selbtngroup {
  left: -33px;
  top: -2px;
}
.date-filter datepicker .mydp .selectiongroup .selbtngroup button {
  background: transparent;
}
.date-filter datepicker .mydp .selector {
  right: 0;
}
.date-filter datepicker .mydp .selector:after {
  right: 7px;
  left: initial;
}

/*
**************************************************************
8. Checkbox
**************************************************************
*/
.checkbox {
  display: block;
}
.checkbox input[type=checkbox] {
  display: none;
}
.checkbox label {
  position: relative;
  display: inline-block;
  padding-top: 3px;
  padding-left: 30px;
  cursor: pointer;
  font-size: 0.6667rem;
  font-weight: 300;
  color: #8f8f8f;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}
.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: "";
  display: inline-block;
}
.checkbox label::before {
  height: 20px;
  width: 20px;
  border: 1px solid var(--secondary-color-light);
  left: 0px;
  border-radius: 2px;
  top: 5px;
}
.checkbox input[type=checkbox]:checked + label::before {
  background-color: var(--primary-color);
  border-color: var(--secondary-color);
}
.checkbox label::after {
  height: 5px;
  width: 13px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
  left: 4px;
  top: 10px;
}
.checkbox input[type=checkbox] + label::after {
  content: none;
}
.checkbox input[type=checkbox]:checked + label::after {
  content: "";
}

/*
**************************************************************
9. Cards 
**************************************************************
*/
.available-position-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 25px;
  margin-bottom: 25px;
  cursor: pointer;
}
.available-position-card.selected {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.available-position-card.selected .position span {
  color: white;
}
.available-position-card.selected .floor {
  color: white;
}
.available-position-card.selected .date {
  color: white;
}
.available-position-card.selected .icon i {
  color: white;
}
.available-position-card .position {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--primary-color);
  padding-bottom: 5px;
}
.available-position-card .position span {
  color: var(--primary-color);
}
.available-position-card .floor {
  font-weight: bold;
  font-size: 14px;
  color: var(--secondary-color);
}
.available-position-card .date {
  font-size: 14px;
  color: var(--secondary-color-light);
}
.available-position-card .icon i {
  font-size: 24px;
  color: var(--primary-color);
  cursor: pointer;
}

/*
**************************************************************
9. NG-SELECT 
**************************************************************
*/
.ng-select .ng-select-container {
  font-family: "SmartBuilding", sans-serif;
  font-weight: normal;
  font-style: normal;
  border: 0;
  padding: 0;
  justify-content: center;
  align-items: end;
}
.ng-select .ng-select-container:hover {
  box-shadow: none !important;
}
.ng-select .ng-select-container input {
  height: auto;
  padding: 0;
}
.ng-select .ng-select-container .ng-value-container {
  padding: 0 !important;
  margin-right: 10px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 1.444rem;
  padding: 0 !important;
}
.ng-select .ng-select-container .ng-arrow-wrapper  {
  margin-bottom: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #e0e0e0;
  white-space: break-spaces !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none !important;
  display: none;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #e0e0e0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0 22px;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
  white-space: nowrap;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #e0e0e0;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #eee;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected:hover {
  background-color: #e0e0e0;
}

.modal {
  background: rgba(0, 0, 0, 0.8);
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: auto;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
@media (max-width: 767px) {
  .modal-dialog {
    width: 90%;
  }
}
.modal-dialog .modal-content {
  border-radius: 4px;
  border: 0;
  background-color: #ffffff;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.36), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}
.modal-dialog .modal-content span:not(.required):not(.selbtngroup) {
  display: block;
}
.modal-dialog .modal-content .modal-info {
  padding: 30px;
}
.modal-dialog .modal-content .modal-info .title {
  font-size: 1.111rem;
  font-weight: bold;
  color: var(--secondary-color);
}
.modal-dialog .modal-content .modal-info .message {
  font-size: 0.8889rem;
  color: var(--secondary-color-light);
}
.modal-dialog .modal-content .modal-footer {
  padding: 8px 18px;
}
.modal-dialog .modal-content .modal-footer button {
  border: 0;
  text-transform: uppercase;
  font-size: 0.7778rem;
  font-weight: bold;
  color: rgba(49, 32, 32, 0.58);
}
.modal-dialog .modal-content .modal-footer button:hover {
  background: none;
}
.modal-dialog .modal-content .modal-footer button:last-child {
  color: var(--primary-color);
}