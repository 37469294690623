@font-face {
  font-family: 'vdp-icons';
  src: url('/assets/fonts/vdp-icons.eot?kz72pl');
  src: url('/assets/fonts/vdp-icons.eot?kz72pl#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/vdp-icons.ttf?kz72pl') format('truetype'),
    url('/assets/fonts/vdp-icons.woff?kz72pl') format('woff'),
    url('/assets/fonts/vdp-icons.svg?kz72pl#vdp-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^='vdp-'],
[class*=' vdp-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vdp-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vdp-supportIcon:before {
  content: '\e93c';
}
.vdp-waves:before {
  content: '\e93b';
}
.vdp-config_lanzaderas:before {
  content: '\e93a';
}
.vdp-calendarParkingmotoQueue:before {
  content: '\e936';
}
.vdp-calendarParkingmotoCharge:before {
  content: '\e937';
}
.vdp-calendarParkingQueue:before {
  content: '\e938';
}
.vdp-calendarParkingCharge:before {
  content: '\e939';
}
.vdp-calendar:before {
  content: '\e93f';
}
.vdp-puesto_comedor:before {
  content: '\e92c';
}
.vdp-config_restaurant:before {
  content: '\e91c';
}
.vdp-parkingMotoBig:before {
  content: '\e935';
}
.vdp-parking_big:before {
  content: '\e933';
}
.vdp-comedor_big:before {
  content: '\e934';
}
.vdp-coche_normal:before {
  content: '\e930';
}
.vdp-coche_electrico:before {
  content: '\e931';
}
.vdp-user-x:before {
  content: '\e92f';
}
.vdp-save:before {
  content: '\e92e';
}
.vdp-puestos-restaurante:before {
  content: '\e92d';
}
.vdp-go-back:before {
  content: '\e92b';
}
.vdp-map:before {
  content: '\e92a';
}
.vdp-reserva-multiple:before {
  content: '\e928';
}
.vdp-buscar-usuario:before {
  content: '\e929';
}
.vdp-reserve-gym-resources:before {
  content: '\e910';
}
.vdp-share:before {
  content: '\e927';
}
.vdp-invitar:before {
  content: '\e926';
}
.vdp-puesto_trabajo:before {
  content: '\e900';
}
.vdp-close-lateral-foto:before {
  content: '\e901';
}
.vdp-equipation-accesorios:before {
  content: '\e902';
}
.vdp-equipation-conferencia:before {
  content: '\e903';
}
.vdp-equipation-impresora:before {
  content: '\e904';
}
.vdp-equipation-mesa:before {
  content: '\e905';
}
.vdp-equipation-portatil:before {
  content: '\e906';
}
.vdp-equipation-tablet:before {
  content: '\e907';
}
.vdp-equipation-telefono:before {
  content: '\e908';
}
.vdp-equipation-television:before {
  content: '\e909';
}
.vdp-equipation-wifi:before {
  content: '\e90a';
}
.vdp-icon_password:before {
  content: '\e90b';
}
.vdp-icon-eye-closed:before{
  content: '\e93e';
}
.vdp-icon-eye-open:before{
  content: '\e93d';
}
.vdp-icon_usuario:before {
  content: '\e90c';
}
.vdp-icon-cancelar:before {
  content: '\e90d';
}
.vdp-icon-mas:before {
  content: '\e90e';
}
.vdp-icon-reserva-express:before {
  content: '\e90f';
}
.vdp-icon-reserva-sala:before {
  content: '\e910';
}
.vdp-label-asistentes:before {
  content: '\e911';
}
.vdp-logout:before {
  content: '\e912';
}
.vdp-navbar-perfil-activo:before {
  content: '\e913';
}
.vdp-navbar-salas:before {
  content: '\e914';
}
.vdp-navbar-salas-activo:before {
  content: '\e915';
}
.vdp-plegar-menu:before {
  content: '\e916';
}
.vdp-salas-asistentes:before {
  content: '\e917';
}
.vdp-salas-incidencia:before {
  content: '\e918';
}
.vdp-salas-alerta:before {
  content: '\e919';
}
.vdp-vista-imagen:before {
  content: '\e91a';
}
.vdp-vista-listado:before {
  content: '\e91b';
}
.vdp-salas-lunch:before {
  content: '\e932';
}
.vdp-filtro:before {
  content: '\e91d';
}
.vdp-android:before {
  content: '\e91e';
}
.vdp-ios:before {
  content: '\e91f';
}
.vdp-navbar-descargar-activo:before {
  content: '\e920';
}
.vdp-gym-resources:before {
  content: '\e91f';
}
.vdp-icon-buscar-sala:before {
  content: '\e921';
}
.vdp-icon-reserva-speed:before {
  content: '\e922';
}
.vdp-icon-reserva-sede:before {
  content: '\e923';
}
.vdp-navbar-parking-activo:before {
  content: '\e924';
}
.vdp-warning:before {
  content: '\e925';
}
