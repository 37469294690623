@import 'dynamic-theme-colors.scss';

/*
**************************************************************
Colors
**************************************************************
*/
$primary-color: var(--primary-color);
$primary-color-light: var(--primary-color-light);
$primary-color-lighter: var(--primary-color-light);
$primary-color-dark: var(--primary-color-dark);
$primary-color-darker: var(--primary-color-darker);
$secondary-color: var(--secondary-color);
$secondary-color-light: var(--secondary-color-light);
$background-color: var(--background-color);
$navbar-background: var(--navbar-background);
$navbar-color-inverse: var(--navbar-color-inverse);
$navbar-color-inverse-light: var(--navbar-color-inverse-light);
$navbar-color-inverse-lighter: var(--navbar-color-inverse-lighter);
$navbar-link-hover: var(--navbar-link-hover);
$navbar-link-hover-background: var(--navbar-link-hover-background);
$logo-background: var(--logo-background);
$logo-color-inverse: var(--logo-color-inverse);
$icon-color-parkingElectric: var(--icon-color-parkingElectric);
$icon-color-parkingWaiting: var(--icon-color-parkingWaiting);

$regular-font: 'SmartBuilding', sans-serif;
$bold-font: 'SmartBuilding Bold', sans-serif;
$light-font: 'SmartBuilding Light', sans-serif;
$square-font: $regular-font;

@font-face {
  font-family: 'SmartBuilding';
  src: url('/assets/fonts/aller_rg-webfont.eot');
  src: url('/assets/fonts/aller_rg-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/aller_rg-webfont.woff2') format('woff2'),
    url('/assets/fonts/aller_rg-webfont.woff') format('woff'),
    url('/assets/fonts/aller_rg-webfont.ttf') format('truetype'),
    url('/assets/fonts/aller_rg-webfont.svg#aller_rg-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SmartBuilding Bold';
  src: url('/assets/fonts/aller_bd-webfont.eot');
  src: url('/assets/fonts/aller_bd-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/aller_bd-webfont.woff2') format('woff2'),
    url('/assets/fonts/aller_bd-webfont.woff') format('woff'),
    url('/assets/fonts/aller_bd-webfont.ttf') format('truetype'),
    url('/assets/fonts/aller_bd-webfont.svg#aller_bd-webfont') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'SmartBuilding Light';
  src: url('/assets/fonts/aller_lt-webfont.eot');
  src: url('/assets/fonts/aller_lt-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/aller_lt-webfont.woff2') format('woff2'),
    url('/assets/fonts/aller_lt-webfont.woff') format('woff'),
    url('/assets/fonts/aller_lt-webfont.ttf') format('truetype'),
    url('/assets/fonts/aller_lt-webfont.svg#aller_lt-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Primary gradient */
@mixin primary-gradient {
  background: $primary-color;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($primary-color),
    to($primary-color-light)
  );
  background: linear-gradient(to bottom, $primary-color 0, $primary-color-light 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-color, endColorstr=$primary-color-light, GradientType=0 );
}

@mixin secondary-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#bd4cc1+0,9c2aa0+100 */
  background: $primary-color-light!important; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($primary-color-light),
    to($primary-color)
  ) !important;
  background: linear-gradient(
    to bottom,
    $primary-color-light 0%,
    $primary-color 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-color-light, endColorstr=$primary-color, GradientType=0 ) !important; /* IE6-9 */
}

/* Primary button */
@mixin primary-button {
  background: $primary-color-light;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($primary-color-light),
    to($primary-color)
  );
  background: linear-gradient(to bottom, $primary-color-light 0, $primary-color 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-color-light, endColorstr=$primary-color, GradientType=0 );
  border-radius: 100px;
  -webkit-box-shadow: 0 7px 11px 0 #000000c0;
  box-shadow: 0 7px 11px 0 #000000c0;
  padding: 12px 20px;
  color: #fff;
  font-weight: bold;
  border: 0;
  transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);

  &:hover {
    background: $primary-color-dark;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from($primary-color-darker),
      to($primary-color-dark)
    );
    background: linear-gradient(to bottom, $primary-color-dark 0, $primary-color-darker 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-color-darker, endColorstr=$primary-color-darker, GradientType=0 );
  }
}

/* Secondary button */
@mixin secondary-button {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#525659+0,1d1e1f+100 */
  background: $primary-color-lighter; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($primary-color-lighter),
    to($primary-color-lighter)
  );
  background: linear-gradient(
    to bottom,
    $primary-color-lighter 0%,
    $primary-color-lighter 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$primary-color-lighter, endColorstr=$primary-color-lighter,GradientType=0 ); /* IE6-9 */
  border-radius: 100px;
  -webkit-box-shadow: 0 7px 11px 0 #000000c0;
  box-shadow: 0 7px 11px 0 #000000c0;
  padding: 12px 20px;
  color: #fff;
  font-weight: bold;
  border: 0;
}

/* Transition */
@mixin transition {
  transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.64, 0.04, 0.12, 1);
}

/*
**************************************************************
Main Logo && image
**************************************************************
*/
$height-logo: 65px;
$height-padding-logo: 15px 0 0;
$default-box-shadow: none;
$default-bg-image: linear-gradient(
  to left,
  rgba(255, 255, 255, 0),
  rgba(0, 0, 0, 0.2)
);
$button-bg-collapse: #fff;
$button-icon-color: $primary-color;
$list-menu-font-family: 'BNPP Square', 'sans-serif';
