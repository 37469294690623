:root {
  --primary-color: #063c67;
  --primary-color-light: #09518b;
  --primary-color-dark: #062d4c;
  --primary-color-darker: #063c67;
  --primary-color-lighter: #042037;
  --secondary-color: #4a4d4e;
  --secondary-color-light: #4a4d4e;
  --background-color: #2d383b;
  --navbar-background: #fff;
  --navbar-color-inverse: #333;
  --navbar-color-inverse-light: #707070;
  --navbar-color-inverse-lighter: #8f8f8f;
  --navbar-link-hover: #fff;
  --navbar-link-hover-background: var(--primary-color-light);
  --logo-background: var(--navbar-background);
  --logo-color-inverse: var(--navbar-color-inverse);
  --icon-color-parkingElectric: #fff;
  --icon-color-parkingWaiting: #fff;
}
